import * as React from "react";
import { useEffect, useState } from "react";
import { FilterState, ItemPriority, ItemState, ItemType, ToDoProps } from "./interface";
import { ToDoEditView } from "./EditView";
import { generateTodosFromRawDate } from "./ActionItem.factory";
import { ActionPanel } from "./ActionPanel/ActionPanelV2";
import { ToDoTable } from "./ToDoTable";
import { createUserName, isToDoOverdue } from "../Utils";
import { useLCMD } from "../../app/LCMContext";
import { FilterModal } from "../Modal/ModalToDoFilter";
import { FilterData } from "../Modal/ModalToDoFilter/filter.interface";
import { intl, TableExportHelper } from "lcmd2framework";
import { IColumn } from "@fluentui/react";
import { useUserMap } from "../hooks/userMap.hook";
import { useToDoModalFilterStore } from "../../app/store/toDoModalFilterStore";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

const mapFilterToToDoProperties: Map<keyof FilterData, keyof ToDoProps> = new Map([
    ["typeFilterData", "type"],
    ["responsibleFilterData", "responsible"],
    ["dateFilterData", "deadline"],
    ["dateFilterData", "identified"],
    ["dateFilterData", "needed"],
    ["dateFilterData", "resolved"],
    ["tradesFilterData", "trade"],
    ["areasFilterData", "process"],
    ["priorityFilterData", "priority"],
    ["statusFilterData", "status"],
    ["raisedByFilterData", "raisedBy"],
]);

export function ToDo() {
    const LCMD = useLCMD();
    const [allItems, setAllItems] = useState<ToDoProps[]>([]);
    const [currentItems, setCurrentItems] = useState<ToDoProps[]>([]);
    const [today, setToday] = useState<number>(0);
    const [ts, setTs] = useState<number>(new Date().getTime());
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [isToDoEditViewOpen, setIsToDoEditViewOpen] = useState<boolean>(false);
    const [adaptTableToContent, setAdaptTableToContent] = useState<boolean>(false);
    const [generatingXLSX, setGeneratingXLSX] = useState(false);
    const [maxAreaLevel, setMaxAreaLevel] = useState<number>(0);
    const { isModalVisible, setIsModalVisible, setModalRoute } = useToDoModalFilterStore();

    const allUsersMap = useUserMap();

    const [filterState, setFilterState] = useState<FilterState>({
        filterColumns: ["action", "issue", "process", "trade", "id", "responsible", "raisedBy", "actionBy", "deadline", "identified", "needed", "resolved"],
        searchText: "",
        filterData: 0,
        flattFilterData: null,
        sortByColumn: null,
    });

    const onSortColumn = (sortState: IColumn) => {
        setFilterState((fs) => {
            saveToDoState({ ...fs, sortByColumn: JSON.parse(JSON.stringify(sortState)) });
            return { ...fs, sortByColumn: JSON.parse(JSON.stringify(sortState)) };
        });
    };

    const onSearch = (searchText) => {
        setFilterState((fs) => {
            saveToDoState({ ...fs, searchText: searchText || "" });
            return { ...fs, searchText: searchText || "" };
        });
    };

    function isFilterEmpty() {
        const filteredData = Object.keys(filterState.filterData || {}).filter(
            (key) => filterState.filterData[key]?.length > 0,
        );
        return !(filteredData.length && Boolean(filterState.filterData));
    }

    const onFilterStateChanged = (filterData: FilterData) => {
        const flattFilterData = {
            typeFilterData: filterData?.typeFilterData?.map((p) => p.key),
            responsibleFilterData: filterData?.responsibleFilterData?.map((p) => p.id),
            dateFilterData: filterData?.dateFilterData,
            tradesFilterData: filterData?.tradesFilterData?.map((t) => t.id),
            areasFilterData: filterData?.areasFilterData?.map((a) => {
                return a.tid;
            }),
            priorityFilterData: filterData?.priorityFilterData?.map((p) => p.priority),
            statusFilterData: filterData?.statusFilterData?.map((p) => p.key),
            raisedByFilterData: filterData?.raisedByFilterData?.map((p) => p.id),
        };
        setFilterState((fs) => {
            saveToDoState({ ...fs, filterData: filterData, flattFilterData: flattFilterData });
            return { ...fs, filterData: filterData, flattFilterData: flattFilterData };
        });
    };

    useEffect(() => {
        LCMD.loadFilterData((data) => {
            setMaxAreaLevel(data.wbs?.reduce((ret, row) => Math.max(ret, row.l), 0));
        });
    }, []);

    LCMD.useTodoEffect(
        (error, data) => {
            if (error) {
                console.warn(error);
            } else {
                setToday(data.today.value);
                const todos = generateTodosFromRawDate(data);
                setAllItems([...todos]);
            }
        },
        [LCMD, setAllItems, allUsersMap],
        ts,
    );

    LCMD.useSettingsEffect(
        ["todostate"],
        (props) => {
            if (props?.user?.todostate) {
                setFilterState(props?.user?.todostate);
            }
        },
        [setFilterState, ts],
    );

    useEffect(() => {
        // for filtering and sorting currentItems on Changes from all items
        if (filterState.searchText || filterState.sortByColumn || filterState.flattFilterData) {
            let res = [...allItems];

            if (filterState.flattFilterData) {
                res = toDoFilter(res);
            }
            if (filterState.searchText) {
                res = searchInToDos(res);
            }
            if (filterState.sortByColumn) {
                res = copyAndSort(res, filterState.sortByColumn.key, filterState.sortByColumn.isSortedDescending);
                setCurrentItems(res);
            }

            setCurrentItems(res);
        } else {
            setCurrentItems([...allItems]);
        }
    }, [allItems, filterState]);

    function saveToDoState(data: FilterState) {
        const ret = {};
        if (data) {
            Object.assign(ret, {
                user: {
                    todostate: data,
                },
            });
            LCMD.setSettingsProps(ret, () => {
                setTs((value) => value + 1);
            });
        }
    }

    function isOverdue(todo: ToDoProps) {
        if (todo.status === ItemState.DONE) {
            return false;
        }

        return todo.status === ItemState.OPEN && todo.deadline < today;
    }

    function toDoFilter(todos: ToDoProps[]): ToDoProps[] {
        // root area always has id 0
        // if root area is selected (inside areasFilterData - an array with selected filter areas ids)  return all todos

        if (filterState.flattFilterData.areasFilterData && filterState.flattFilterData.areasFilterData[0] == 0) {
            return todos;
        }

        return todos.filter((toDo) => {
            const fulfillments = Object.keys(filterState.flattFilterData).map((k) => {
                const condition = filterState.flattFilterData[k];
                if (!condition || condition?.length === 0) {
                    return true;
                }

                return (
                    condition.filter((v) => {
                        const toDoValue = toDo[mapFilterToToDoProperties.get(k as keyof FilterData)];

                        if (k == "dateFilterData") {
                            return toDoValue < v.endDate && toDoValue > v.startDate;
                        }

                        if (k == "tradesFilterData") {
                            return toDoValue[0] ? toDoValue[0].trid == v : false;
                        }

                        if (k == "areasFilterData") {
                            // @ts-ignore
                            return toDoValue ? toDoValue.areaPath?.filter((p) => p.id == v).length > 0 : false;
                        }

                        if (k == "statusFilterData" && v == "overdue") {
                            return isOverdue(toDo);
                        }

                        if (Array.isArray(toDoValue)) {
                            return toDoValue.includes(v);
                        }

                        if (typeof toDoValue == "string" || typeof toDoValue == "number") {
                            return toDoValue === v;
                        }

                        return false;
                    }).length > 0
                );
            });

            return fulfillments.every((x) => x);
        });
    }

    function searchInToDos(todos: ToDoProps[]): ToDoProps[] {
        return todos.filter((item) => {
            return (
                !filterState.searchText ||
                filterState.filterColumns.some((key) => {
                    if (key === "process") {
                        return item.process.name.toLowerCase().indexOf(filterState.searchText.toLowerCase()) > -1;
                    } else {
                        let searchedText: string;
                        if (key == "raisedBy" || key == "responsible") {
                            searchedText = item[key].map((userId) => createUserName(allUsersMap[userId])).join(",");
                        } else if (key == "deadline") {
                            searchedText = new Date(item[key]).toLocaleDateString();
                        } else {
                            searchedText = item[key].toString();
                        }

                        return searchedText.toLowerCase().indexOf(filterState.searchText.toLowerCase()) > -1;
                    }
                })
            );
        });
    }

    function toggleStatus(item: ToDoProps) {
        const status = item.status === ItemState.OPEN ? ItemState.DONE : ItemState.OPEN;
        if (item.type === ItemType.STABILITY_CRITERIA) {
            WebAppTelemetryFactory.trackEvent("stability-criteria-todo-status-changed", {
                checked: status,
            });
        } else if (item.type === ItemType.ACTION_ITEM) {
            WebAppTelemetryFactory.trackEvent("action-item-todo-status-changed", {
                checked: status,
            });
        }
        LCMD.setOrCreateTodoItemState(
            { pid: item.process.pid, id: item.id },
            { value: status },
            status === ItemState.DONE ? {resolved : {value : +new Date()}} : undefined,
            (error, data) => {
                if (error) {
                    console.error(error);
                } else {
                    setTs(new Date().getTime());
                }
            },
        );
    }

    function copyAndSort(items: ToDoProps[], columnKey: string, isSortedDescending?: boolean): ToDoProps[] {
        switch (columnKey) {
            case "status":
                if (isSortedDescending) {
                    return items.slice(0).sort((a, b) => {
                        // DONE state to the bottom
                        if (a.status === ItemState.DONE) return 1;
                        if (b.status === ItemState.DONE) return -1;

                        if (isToDoOverdue(a, today)) return -1;
                        if (isToDoOverdue(b, today)) return 1;
                    });
                } else {
                    return items.slice(0).sort((a, b) => {
                        // DONE state to the top
                        if (a.status === ItemState.DONE) return -1;
                        if (b.status === ItemState.DONE) return 1;

                        if (isToDoOverdue(a, today)) return 1;
                        if (isToDoOverdue(b, today)) return -1;
                    });
                }
            case "trade":
                return items.slice(0).sort((a, b) => {
                    const aa = a[columnKey].map((t) => t.name).join(" ");
                    const bb = b[columnKey].map((t) => t.name).join(" ");
                    return isSortedDescending ? bb.localeCompare(aa) : aa.localeCompare(bb);
                });
            case "deadline":
            case "identified":
            case "needed":
            case "resolved":
                return items
                    .slice(0)
                    .sort((a, b) =>
                        (
                            isSortedDescending
                                ? new Date(a[columnKey]) < new Date(b[columnKey])
                                : new Date(a[columnKey]) > new Date(b[columnKey])
                        )
                            ? 1
                            : -1,
                    );
            case "process":
                return items
                    .slice(0)
                    .sort((a, b) =>
                        isSortedDescending
                            ? b[columnKey].name.toString().localeCompare(a[columnKey].name)
                            : a[columnKey].name.toString().localeCompare(b[columnKey].name),
                    );
            default:
                return items
                    .slice(0)
                    .sort((a, b) =>
                        isSortedDescending
                            ? b[columnKey].toString().localeCompare(a[columnKey])
                            : a[columnKey].toString().localeCompare(b[columnKey]),
                    );
        }
    }

    function closeEditView() {
        //todo: only set ts if modal did change the values
        setIsToDoEditViewOpen(false);
        setTs(new Date().getTime());
    }

    function exportToExcelFile() {
        setGeneratingXLSX(true);
        const helper: TableExportHelper = new TableExportHelper();
        helper.pushHeader(intl.get("ToDoExportFile.id"));
        helper.pushHeader(intl.get("ToDoExportFile.prio"));
        helper.pushHeader(intl.get("ToDoExportFile.issue"));
        helper.pushHeader(intl.get("ToDoExportFile.action"));
        helper.pushHeader(intl.get("ToDoExportFile.trade"));
        helper.pushHeader(intl.get("ToDoExportFile.processId"));
        helper.pushHeader(intl.get("ToDoExportFile.process"));

        for (let i = 0; i < maxAreaLevel; i++) {
            helper.pushHeader(intl.get("ToDoExportFile.area") + ` ${i}`);
        }

        helper.pushHeader(intl.get("ToDoExportFile.raisedBy"));
        //helper.pushHeader(intl.get("ToDoExportFile.raisedDate"));
        helper.pushHeader(intl.get("ToDoExportFile.responsible"));
        helper.pushHeader(intl.get("ToDoExportFile.actionBy"));
        helper.pushHeader(intl.get("ToDoExportFile.type"));
        helper.pushHeader(intl.get("ToDoExportFile.status"));
        helper.pushHeader(intl.get("ToDoExportFile.identified"));
        helper.pushHeader(intl.get("ToDoExportFile.needed"));
        // helper.pushHeader(intl.get("ToDoExportFile.deadline"));
        helper.pushHeader(intl.get("ToDoExportFile.resolved"));

        currentItems.forEach((item) => {
            let status: string = item.status;
            if (item.status == ItemState.OPEN && isToDoOverdue(item, today)) {
                status = intl.get("ToDoExportFile.statusOverdue");
            }
            if (item.status == ItemState.OPEN && !isToDoOverdue(item, today)) {
                status = intl.get("ToDoExportFile.statusOpen");
            }
            if (item.status == ItemState.DONE) {
                status = intl.get("ToDoExportFile.statusDone");
            }

            let priority: string = item.priority.toString();
            if (item.priority == ItemPriority.LOW) {
                priority = intl.get("ToDoExportFile.priorityLow");
            }
            if (item.priority == ItemPriority.MEDIUM) {
                priority = intl.get("ToDoExportFile.priorityMedium");
            }
            if (item.priority == ItemPriority.HIGH || item.priority == ItemPriority.HIGHER) {
                priority = intl.get("ToDoExportFile.priorityHigh");
            }

            let type: string = item.type;
            if (item.type === ItemType.ACTION_ITEM) {
                type = intl.get("ToDoExportFile.typeAction");
            }
            if (item.type === ItemType.STABILITY_CRITERIA) {
                type = intl.get("ToDoExportFile.typeStability");
            }

            const areaPathTemp = item?.process?.areaPath;
            const areaPath = [];
            for (let i = 0; i < maxAreaLevel - areaPathTemp.length; i++) {
                areaPath.push("");
            }

            let row = [
                `${item.id}`,
                priority,
                item.issue,
                item.action,
                item.trade.map((t) => t.name).join(","),
                item.process.pid,
                item.process.name,
                item.raisedBy.map((userId) => createUserName(allUsersMap[userId])).join(","),
                //new Date(0),
                item.responsible.map((userId) => createUserName(allUsersMap[userId])).join(","),
                item.actionBy,
                type,
                status,
                item.identified === null ? '' : new Date(item.identified).toDateString(),
                item.needed === null ? '' : new Date(item.needed).toDateString(),
                // item.deadline === null ? '' : new Date(item.deadline).toDateString(),
                item.resolved === null ? '' : new Date(item.resolved).toDateString(),
            ];

            row = row.slice(0, 6).concat(areaPathTemp.map((a) => a.name).concat(areaPath), row.slice(6));
            helper.pushRow(row);
        });

        LCMD.generateXLSX(helper, { name: "todo.xlsx", type: "todo-xlsx" }, () => {
            setGeneratingXLSX(false);
        });
    }

    function resetFilterGoToRoute() {
        // setRoute("/");
        setModalRoute("/");
    }

    return (
        <div>
            <ActionPanel
                exportToDo={exportToExcelFile}
                exportOnProcess={generatingXLSX}
                onChangeAdaptTableToContent={setAdaptTableToContent}
                onOpenModalFilter={() => {
                    //setShowFilterModal(true);
                    setIsModalVisible(true);
                }}
                onChangeSearchText={onSearch}
                initSearchText={filterState.searchText}
                filterDataIsEmpty={isFilterEmpty()}
            />
            <ToDoTable
                currentItems={currentItems}
                allUsersMap={allUsersMap}
                today={today}
                onChangeSortState={onSortColumn}
                onSelectionChanged={setSelectedIndices}
                onUseRoute={(r) => {
                    // setRoute(r);
                    setModalRoute(r);
                    // setShowFilterModal(true);
                    setIsModalVisible(true);
                }}
                openEditView={() => {
                    setIsToDoEditViewOpen(true);
                }}
                onToggleStatus={toggleStatus}
                isMultiline={adaptTableToContent}
            />
            {isToDoEditViewOpen ? (
                <ToDoEditView
                    {...{
                        todos: selectedIndices.map((i) => currentItems[i]),
                        isOpen: isToDoEditViewOpen,
                        createItemType: selectedIndices.map((i) => currentItems[i].type)[0],
                        onCloseEditView: closeEditView,
                    }}
                />
            ) : (
                ""
            )}
            {isModalVisible ? (
                <FilterModal applyFilter={onFilterStateChanged} initFilterData={filterState.filterData} />
            ) : null}
        </div>
    );
}
